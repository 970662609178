export default {
  yes: "Yes",
  no: "No",
  switchLanguage: "Switch language",
  menuoverzicht: "My overview",
  beheer: "Management",
  searchPlaceholder: "Search...",
  new: "New",
  newWorkflow: "New continuous workflow",
  newCampaign: "New campaign workflow",
  details: "Details",
  select: "Select",
  sortMostRecent: "Most recent change",
  sortTitle: "Title",
  sortStartDate: "Start date",
  sortName: "Naam",
  save: "Save",
  cancel: "Cancel",
  active: "Active",
  today: "Today",
  now: "Now",
  minutes: "minutes",
  hours: "hours",
  days: "days",
  weeks: "weeks",
  months: "months",
  minute: "minute",
  hour: "hour",
  day: "day",
  week: "week",
  month: "month",
  close: "Close",
  undo: "undo action",
  insert: "Insert",
  collapse: "Collapse",
  expand: "Expand",
  send: "Send",
  overwrite: "Overwrite",
  logout: "Log out",
  application: "Application",
  settings: "Settings",
  customerService: "Customer service",
  rapportage: "Reports",
  dashboard: "Dashboard",
  doelgroepen: "Target groups",
  lef: "LEF",
  actieveKlant: "Active customers",
  werkplaatsplanner: "Workshopplanner",
  webtracking: "Webtracking",
  voertuigbronnen: "Vehicle sources",
  showInactive: "Show inactive",
  omschrijving: "Description",
  voorbeeld: "Example",

  workflows: {
    title: "Workflows",
    searchPlaceholder: "Search workflows...",
    when: "When",
    category: "Category",
    labels: "Labels",
    copy: "Copy",
  },

  workflow: {
    berichtaccorderen: "Berichten vooraf goedkeuren",
    title: "Workflow",
    copied: "Copy of {title}",
    subject: "Subject",
    insertTitle: "Insert title...",
    insertSubject: "Insert subject...",
    selectLabel: "Select label(s)",
    panelDetailTitle: "General information",
    starttitel: "Start moment",
    startmoment: "Start",
    startprerequisites: "Start prerequisites",
    addstartprerequisite: "Add",
    selectstart: "Select a start moment",
    selectwacht: "Insert a time span",
    selectkwalificatie: "Select a kwalification",
    contains: "Contains",
    selectleadtype: "Select a lead type",
    selectsoortlead: "Select a lead kind",
    selectleadcartype: "Select a car type",
    selectleadsource: "Select a lead source",
    selectleadcarmake: "Select make",
    selectaftersalescarmake: "Select make",
    selectaftersalescartype: "Kies voertuig categorie",
    selectleadbranch: "Select a branch",
    selectsalesbranch: "Select a branch",
    selectorderbranch: "Select a branch",
    selectGarantie: "Select warranty type",
    garantieSoort: "Warranty type",
    selectendtitle: "End",
    selectsteptitle: "Steps",
    selectActiegroep: "Select an actiegroep",
    stepbericht: "Template",
    stepLEF: "LEF",
    StepLefTitle: "LEF",
    stepLEFKwalificatie: "Kwalification",
    stepLEFleadType: "Lead type",
    stepLEFDescription: "Description",
    stepLEFInsertTitle: "Insert a description...",
    stepVoorwaarde: "Condition",
    stepVoorwaardeTitle: "Condition",
    stepVoorwaardeDescription: "Description",
    selectVoorwaarde: "Select a condition",
    selectVoorwaardeAfsluitredenSucces: "Select a reason",
    selectVoorwaardeAfsluitreden: "Select a reason",
    selectVoorwaardeAfsluitredenSales: "Select a reason",
    selectVoorwaardeLopendeWorkflows: "Select other workflow(s)",
    selectVoorwaardeOpenLeadType: "Select a leadtype",
    selectVoorwaardeHeeftVoertuig: "Select a comparison",
    selectVoorwaardeBrandSame: "For same make",
    selectVoorwaardeBrandIndependent: "Same make or other makes",
    merk: "Make",
    profiel: "Profile",
    leadtype: "Leadtype",
    leadinitiatief: "Initiative",
    hasOpenSalesLead: "Has open saleslead",
    hasOpenAftersalesLead: "Has open aftersaleslead",
    hasNoOpenLead: "Has no open lead",
    hasNoOpenSalesLead: "Has no open saleslead",
    hasNoOpenAftersalesLead: "Has no open aftersaleslead",
    hasNoOpenSalesOrAflevering: "Has no open sales or delivery",
    hasNoOpenSales: "Has no open sales",
    hasNoOpenAflevering: "Has no open delivery",
    hasTelefoon: "Has phone number",
    hasNoTelefoon: "Has no phone number",
    hasEmail: "Has email address",
    hasNoEmail: "Has not email address",
    kanBenaderen: "Can contact",
    kanNietBenaderen: "Can not contact",
    abort: "Cancel workflow",
    noAbort: "Let workflow run",
    tileAbortDescription: "May abort workflow",
    preconditionDuringWorkflow: "If condition doesn't apply anymore on running workflow",
    expiratie: "Automatically 'no'",
    selectlinkcklick: "Select a link",
    stepEinde: "Finish",
    stepEindeResult: "Result",
    stepTimer: "Timespan",
    stepTimerTitle: "Wait",
    selectBericht: "Select a template",
    changeBericht: "Change template",
    noBericht: "No template selected",
    selectResultaat: "Select a result",
    stepsRemoved: "removed",
    saved: "Workflow is saved",
    selectKlantCategorie: "Select a customer category",
    orderVoertuigCategorie: "Select a vehicle category",
    orderVoertuigBrandstof: "Kies voertuigbrandstof",
	  keepWhichPath: "Continue workflow with ",
    optin: "Opt\u2011in: ",
    checkOptin: "Check opt\u2011in: ",
    noOptin: "No opt\u2011in",
    recenteLeadDelta: "In the last",
    hasRecentSalesLead: "Has recently created saleslead",
    hasRecentAftersalesLead: "Has recently created aftersaleslead",
    hasNoRecentLead: "Has no recently created lead",
    hasNoRecentSalesLead: "Has no recently created saleslead",
    hasNoRecentAftersalesLead: "Has no recently created aftersaleslead",
    hasNoRecentSales: "Has no recently created sales",
    leadInitiatiefType: {
      onbekend: "Unknown",
      klant: "Customer",
      autobedrijf: "Dealership",
      importeur: "Manufacturer"
    },
    selectInitiatiefType: "Select initiatief",
    subconditions: "Subconditions",
    meetsSubconditions: "Meets",
    allSubconditions: "All subconditions",
    anySubconditions: "One of the subconditions",
    addSubcondition: "Add",
    subconditionCriteria: "Choose criteria",
    tileSubconditionsAllDescription: "Meets all<br>{count} subconditions",
    tileSubconditionsAnyDescription: "Meets any of<br>{count} subconditions",
    tileSubconditionsCountDescription: "Contains {count} subcondition| Contains {count} subconditions",
    category: {
      all: "All workflows",
      lead: "Lead",
      sales: "Sales",
      aftersales: "Aftersales",
      delivery: "Delivery",
      campaign: "Campaign"
    },
    errorMessages: {
      readonly: "This workflow can not be edited because the campaign has already started.",
      workflowTitle: "A title is required.",
      startStepNoTrigger: "A start moment is required.",
      startStepNoGarantie: "A warranty type is required.",
      startStepNoStartMoment: "A start moment is required.",
      startStepNoStartTijd: "A start time is required.",
      startStepNoAantalPerDag: "A maximum for daily workflow executions is required.",
      startStepNoDoelgroepen: "At least one target group must be selected.",
      startStepNoEntiteit: "A choice must be made to start workflow for each relation or vehicle.",
      startStepInactiveDoelgroep: "One or more target groups are inactive.",
      startStepNoVoertuigEntiteit: "Every selected target group needs to contain a vehicle.",
      eindStep: "One or more finish steps are missing.",
      eindStepNoResult: "Results in finish steps are required.",
      wachtStep: "A timespan in wait steps are required.",
      berichtStep: "A template must be selected for a template step.",
      berichtStepMissingEntities: "Een {entiteiten} ontbreekt in de workflow.",
      voorwaardeStepMissingEntities:
      "De workflow bevat {entiteiten} die niet ge-evalueerd kunnen worden.",
      lefStep: {
        omschrijving: "A Description in LEF steps are required.",
        kwalificaties: "A Qualification in LEF steps are required.",
        leadtype: "Lead kinds in LEF steps are required for sales and afleveringen."
      },
      voorwaardeCombinatieStep: {
        criteria: "Criteria in conditional steps with multiple subconditions are required."
      },
      voorwaardeStep: {
        criteria: "Criteria in conditional steps are required.",
        gemist: "Missed closing reasons in conditional steps are required.",
        soortLead: "Lead kinds in conditional steps are required.",
        gewensteAutosoorten:
          "Required car types in conditional steps are required.",
        carmake: "Salect a make in conditional steps are required.",
        vestigingen: "A selected branch in conditional steps are required.",
        afsluitRedenen: "Closing successes in conditional steps are required.",
        link: "Links in conditional steps are required.",
        gemistSales:
          "Missed closing reasons in conditional steps are required.",
        salesAutosoorten:
          "Sales closing reasons in conditional steps are required.",
        aftersalesVoertuigCategorieen:
          "Voertuigcategorie in voorwaardestappen zijn verplicht.",
        salesAutomake: "Make in conditional steps are required.",
        orderAutomake: "Make in conditional steps are required.",
        vestigingenExternSales:
          "A selected branch in conditional steps are required.",
        orderAutosoorten:
          "Sales closing reasons in conditional steps are required.",
        vestigingenExternOrder:
          "A selected branch in conditional steps are required.",
        actiegroepVerkocht:"A selected actiegroep verkocht in conditional steps are required.",
        actiegroepGoedgekeurd:"A selected actiegroep goedgekeurd in conditional steps are required.",
        actiegroepBesteld:"A selected actiegroep besteld in conditional steps are required.",
        actiegroepAutoBinnen:"A selected actiegroep auto binnen in conditional steps are required.",
        actiegroepAfspraakLevering:"A selected actiegroep afspraak levering in conditional steps are required.",
        actiegroepAfgeleverd:"A selected actiegroep afgeleverd in conditional steps are required.",
        klantCategorie:"A Customer catagory in conditional steps are required.",
        kwalificatie: "A qualificationKwalificatie in conditional steps is required.",
        leadbron: "A leadsource in conditional steps is required.",
        lopendeWorkflows:
        "Lopende workflows in voorwaardestappen zijn verplicht.",
        timespan: "Periode in voorwaardestappen zijn verplicht.",
        referenceid: "Referentiedatum in voorwaardestappen zijn verplicht.",
        garantie: "Warranty's in conditional steps are required.",
        heeftInitiatief: "Initiative in conditional steps are required.",
        heeftOpenLead: "Leadtype in conditional steps are required",
        optinProfiel: "Profile with opt-in check in conditional steps are required",
        optinMedium: "Medium with opt-in check in conditional steps are required",
        expiratieMaxSubvoorwaarden: "Wachttermijn kan niet worden gebruikt bij meerdere subvoorwaarden",
        bedragnegatief: "Geen geldig bedrag ingevuld.",
        aantalnegatief: "Geen geldig aantal ingevuld.",
        invalidCompare: "Geen geldig vergelijker ingevuld."
      },
      campagneAantalLimitExceeded: "Het maximum aantal dat per dag verstuurd mag worden is gelimiteerd op {count}. Indien dit opgehoogd dient te worden, wordt u verzocht contact op te nemen met onze Customer Service.",
      campagneStartToday: "Let op: Deze campagne zal over {count} minuten starten.",
      addDoelgroep: "Add",
      multipleDoelgroepInformation: "Wanneer een relatie zich in meerdere doelgroepen bevindt, wordt voor het vullen van samenvoegvelden in berichten de bovenste doelgroep gebruikt, in dit geval: {doelgroepNaam}"
    }
  },

  sjablonen: {
    title: "Templates",
    searchPlaceholder: "Search template...",
    blocks: "Blocks",
    general: "Miscellaneous",
    inworkflows: "Workflows",
    communication: "Communication",
    category: "Category",
    workflow: "Workflow",
    make: "Make",
    labels: "Labels",
    email: "Email",
    sms: "Sms",
    copy: "Copy",
    createTemplate: "Create template",
    createdTemplate: "Template created"
  },

  sjabloon: {
    copied: "Copy of {title}",
    panelTitle: "Template details",
    panelDetailTitle: "General information",
    title: "Template",
    subject: "Subject",
    category: "Category",
    label: "Label",
    insertTitle: "Insert title...",
    insertSubject: "Insert subject...",
    chooseCategory: "Pick a category",
    allCategories: "All categories",
    actief: "Active",
    inactief: "Inactive",
    allLabels: "Alle labels",
    selectLabel: "Select label(s)",
    email: "Email",
    sms: "Sms",
    opgeslagen: "Saved",
    mergetags: "Merge tags",
    selectmergetag: "Insert merge tag",
    undoSMS: "SMS is removed",
    undoEmail: "Email is removed",
    chooseBericht: "Pick a template",
    modelSpecificLayout: "Model specific lay-out",
    merk: "Make",
    allMakes: "All Makes",
    selectMerk: "Pick a make",
    nonSelectedMerk: "All makes",
    selectedMerk: "Other makes",
    notAvailableMerk: "Make not available",
    notAvailableOpties: "No options available",
    noResult: "No results found",
    merkDeleted: "Make is deleted",
    preview: "Preview",
    sendtest: "Send test",
    saved: "Template saved successfully",
    saveRow: "Save row",
    savedRow: "Saved row",
    warnExistingRowUpdate: "This row is already saved as",
    voorkeurinformatie:
      "The tab order determines the prefered medium when sending",
    noWorkflow: "Not applied within workflows",
    testMailDetailTitle: "Test email",
    testSMSDetailTitle: "Test SMS",
    testSMSTitle: "Send test SMS to",
    webtrackingactive: "Webtracking",
    insertPhoneNumber: "Insert cell phone number",
    testEmailTitle: "Send test email to",
    insertEmail: "Insert email address...",
    testMailSend: "Test email is send",
    testMailSendFailed: "Failed to send email",
    testSMSSend: "Test SMS is send",
    testSMSSendFailed: "Failed to send SMS",
    errormessages: {
      title: "Field template is required",
      omschrijving: "Field subject is required",
      testemailaddress: "A valid Emailaddress is required",
      testephonenumber: "A valid cell phone number is required"
    }
  },

  sentMessages: {
    versturen: "Versturen",
    menuTitle: "Unsent messages",
    title: "Unsent message ({count}) | Unsent messages ({count})",
    workflow: "Workflow",
    email: "E-mail",
    sms: "SMS",
    recipient: "Recipient",
    status: "Error state",
    errorMessage: "Error message",
    delete: "Remove",
    confirmDelete: "Confirm",
    communication: "Communication",
    berichtTitel: "Message title",
    badgeTitle: '1 unsent message | {count} unsent messages'
  }
};
