export default {
  yes: "Ja",
  no: "Nee",
  okayCapital: "OK",
  yessmall: "ja",
  nosmall: "nee",
  switchLanguage: "Wijzig taal",
  menuoverzicht: "Mijn overzicht",
  beheer: "Beheer",
  searchPlaceholder: "Zoeken...",
  new: "Nieuw",
  newWorkflow: "Nieuwe doorlopende workflow",
  newCampaign: "Nieuwe campagne workflow",
  details: "Details",
  navigationBack: "Terug",
  select: "Selecteer",
  apply: "Toepassen",
  sortMostRecent: "Datum aangepast",
  sortTitle: "Titel",
  sortStartDate: "Startdatum",
  sortName: "Naam",
  save: "Opslaan",
  cancel: "Annuleren",
  active: "Actief",
  today: "Vandaag",
  now: "Nu",
  minutes: "minuten",
  hours: "uren",
  days: "dagen",
  weeks: "weken",
  months: "maanden",
  minute: "minuut",
  hour: "uur",
  day: "dag",
  week: "week",
  month: "maand",
  close: "Sluiten",
  undo: "ongedaan maken",
  insert: "Invoegen",
  collapse: "Inklappen",
  expand: "Uitklappen",
  send: "Verzenden",
  overwrite: "Overschrijven",
  logout: "Uitloggen",
  application: "Applicatie",
  settings: "Instellingen",
  customerService: "Klantenservice",
  rapportage: "Rapportage",
  dashboard: "Dashboard",
  doelgroepen: "Doelgroepen",
  lef: "LEF",
  stepBericht: "Bericht",
  stepVoorwaarde: "Voorwaarde",
  stepWacht: "Wacht",
  stepEinde: "Einde",
  actieveKlant: "Actieve klant",
  werkplaatsplanner: "Werkplaatsplanner",
  webtracking: "Webtracking",
  voertuigbronnen: "Voertuigbronnen",
  showInactive: "Toon inactief",
  copyCampagne: "Campagne kopiëren",
  omschrijving: "Omschrijving",
  voorbeeld: "Voorbeeld",
  timespan: "Periode",
  vanaf: "Vanaf",
  inclusiveTill: "Tot en met",
  vestiging: "Vestiging",
  vestigingen: "Vestigingen",
  vestigingenAll: "Alle vestigingen",
  vestigingenSelect: "Selecteer vestiging(en)",
  voertuigFilter: "Soort auto",
  voertuigFilterAll: "Alle soorten",
  voertuigFilterSelect: "Selecteer soort(en)",
  exporteren: "Exporteren",
  soortAuto: {
    Nieuw: "Nieuw",
    Occasion: "Occasion",
    Voorraad: "Voorraad"
  },
  widget: {
    leadsalesTitle: "Sales{linebreak}leads",
    leadaftersalesTitle: "Aftersales{linebreak}leads",
    salesTitle: "Sales",
    aflevering: "Afleveringen",
    aftersales: "Aftersales",
    totals: "Totaal: {count}",
    total: "Totaal",
    widgetstart: "gestart op: {datum}",
    widgetended: "afgerond op: {datum}",
    retrieving: "Bezig met ophalen...",
    unknown: "Onbekend",
    ongoing: "Lopend",
    drilldownWorkflowLast: "Workflows voor laatste wijziging",
    drilldownWorkflowLastChange: "Deze workflow is laatst gewijzigd op {lastChangedDateFormatted}. Workflows die gestart zijn voor de laatste wijziging worden in onderstaande tabel weergegeven.",
    errorConnectionReport: "Fout tijdens het ophalen van de rapportage, probeer het later opnieuw of neem contact op met onze Customer Service",
    errorOldBrowser:  "Uw browser wordt niet ondersteund, rapportage kan niet worden opgehaald",
    errorConnection: "Het opzetten van een verbinding voor real-time updates is niet gelukt.",
    errorConnectionRetry: "Ververs deze pagina om actuele gegevens op te halen.",
    noData: "Geen data beschikbaar",
    unknownWorkflow: "Onbekende workflow",
    headersFullsize:{
      workflow: "Workflow",
      totals: "Totaal",
      success: "Succesvol",
      failures: "Niet succes.",
      ongoing: "Lopend",
      converted: "Conversie",
      notApplicable: "N.v.t.",
    },
    headersTinysize:{
      workflow: "Workflow",
      totals: "Totaal",
      success: "Succes",
      failures: "Niet succes",
      ongoing: "Lopend",
      converted: "Conv.",
      notApplicable: "N.v.t.",
    }
  },
  workflows: {
    soortLead: "Soort lead",
    lefStepCopy: "Overnemen uit vorig lead",
    title: "Workflows",
    titleCampagne: "Workflows",
    filteredCount: "{tabtitle} ({count} van de {total})",
    searchPlaceholder: "Zoek workflow...",
    when: "Wanneer",
    starttrigger: "Bij wijzigen datum",
    aantalStartvoorwaardenMore: "Startvoorwaarde | Startvoorwaarden",
    aantalStartvoorwaarden: "{count} Startvoorwaarde | {count} Startvoorwaarden",
    entiteitTextRelaties : "{count} relatie | {count} relaties",
    entiteitTextVoertuigen : "{count} voertuig | {count} voertuigen",
    entiteitTextEntiteiten : "{count} entiteit | {count} entiteiten",
    category: "Categorie",
    labels: "Labels",
    copy: "Kopiëren",
    medium: "Medium",
    chooseMedium: "Kies medium",
    categoryChoice: {
      all: "Alle workflows",
      lead: "Lead workflows",
      sales: "Sales workflows",
      aftersales: "Aftersales workflows",
      delivery: "Aflevering workflows"
    },
    campagnePeriod: {
      custom: "Vrije periode",
      fromNow: "Toekomst",
      lastMonths: "Binnen 3 maanden",
      year: "Dit jaar",
      lastYear: "Vorig jaar"
    },
    periods: {
      custom: "Vrije periode",
      today: "Vandaag",
      yesterday: "Gisteren",
      thisweek: "Deze week",
      lastweek: "Vorige week",
      thismonth: "Deze maand",
      lastmonth: "Vorige maand",
      thisquarter:"Dit kwartaal",
      lastquarter:"Vorige kwartaal",
      year: "Dit jaar",
      lastYear: "Vorig jaar"
    }
  },

  workflow: {
    actionCenter: "Workflow centreren", 
    toCenter: "Centreren", 
    resetZoomFactor: "Dubbelklik voor 100%",
    startMore: "Toon alles",
    startLess: "Toon minder",
    onbekend: "Onbekend",
    berichtaccorderen: "Berichten vooraf goedkeuren",
    title: "Workflow",
    doelgroep: "Doelgroep",
    doelgroepTitle: "{count} doelgroep|{count} doelgroepen",
    tabWorkflows: "Doorlopende workflows",
    tabCampagnes: "Campagne workflows",
    aantalRelaties: "{count} relatie|{count} relaties",
    campagneStartDay: "gestart op {day}",
    campagneFinalDay: "afgerond op {day}",
    subject: "Onderwerp",
    copied: "Kopie van {title}",
    campagneDoelgroepen: "Doelgroepen",
    campagneSearchDoelgroepen: "Zoek doelgroep...",
    searchNoResults: "Geen resultaten",
    paneltitle: "Workflow details",
    insertTitle: "Voer een titel in...",
    insertSubject: "Voer een omschrijving in...",
    saveRow: "Als nieuwe rij opslaan",
    selectLabel: "Selecteer label(s)",
    panelDetailTitle: "Algemene informatie",
    starttitel: "Startmoment",
    startmoment: "Start",
    startprerequisites: "Startvoorwaarden",
    addstartprerequisite: "Toevoegen",
    addedstartprerequisite: "Toegevoegd",
    selectstart: "Kies een startmoment",
    selectwacht: "Voer een periode in",
    selectkwalificatie: "Kies een kwalificatie",
    selectOptin: "Kies profiel",
    selectCompareMake: "Kies merk vergelijking",
    selectFromDropdown: "Kies optie",
    contains: "Bevat",
    containsnot: "Bevat niet",
    itContains: "{item} bevat",
    itContainsnot: "{item} bevat niet",
    selectleadtype: "Kies een lead type",
    selectleadinitiatief: "Kies een lead initiatief",
    selectsoortlead: "Kies lead soort(en)",
    selectsoortleadsingle: "Kies een soort lead",
    selectleadsource: "Kies een leadbron",
    selectleadcartype: "Kies soort gewenste auto",
    selectleadcarmake: "Kies gewenste merk(en)",
    selectleadcartypespecified: "Kies soort voertuig",
    selectaftersalescarmake: "Kies merk(en)",
    selectaftersalescartype: "Kies voertuigcategorie",
    selectleadbranch: "Kies vestiging(en)",
    selectsalesbranch: "Kies vestiging(en)",
    selectorderbranch: "Kies vestiging(en)",
    selectGarantie: "Selecteer garantiesoort",
    selectFactuursoort: "Selecteer factuursoort",
    garantieSoort: "Garantiesoort",
    selectendtitle: "Einde",
    selectsteptitle: "Stappen",
    selectActiegroep: "Kies actiegroep(en)",
    stepbericht: "Bericht",
    stepLEF: "LEF",
    StepLefTitle: "LEF",
    stepLEFKwalificatie: "Kwalificatie",
    stepLEFDescription: "Omschrijving",
    stepLEFInsertTitle: "Voer een omschrijving in...",
    stepLEFleadType: "Leadtype",
    stepLEFLeadInitiatief: "Initiatief",
    stepVoorwaarde: "Voorwaarde",
    stepVoorwaardeTitle: "Voorwaarde",
    stepVoorwaardeDescription: "Omschrijving",
    selectVoorwaarde: "Kies een voorwaarde",
    selectVoorwaardeAfsluitredenSucces: "Kies afsluitreden(en)",
    selectVoorwaardeAfsluitreden: "Kies afsluitreden(en)",
    selectVoorwaardeAfsluitredenSales: "Kies afsluitreden(en)",
    selectVoorwaardeLopendeWorkflows: "Kies gestarte workflow(s)",
    selectVoorwaardeOpenLeadType: "Kies een leadtype",
    selectVoorwaardeHeeftVoertuig: "Kies een vergelijking",
    selectVoorwaardeBrandSame: "Voor hetzelfde merk",
    selectVoorwaardeBrandIndependent: "Hetzelfde of andere merken",
    merk: "Merk",
    profiel: "Profiel",
    leadtype: "Leadtype",
    leadinitiatief: "Initiatief",
    hasOpenSalesLead: "Heeft openstaande saleslead",
    hasOpenAftersalesLead: "Heeft openstaande aftersaleslead",
    hasNoOpenLead: "Heeft geen openstaande lead",
    hasNoOpenSalesLead: "Heeft geen openstaande saleslead",
    hasNoOpenAftersalesLead: "Heeft geen openstaande aftersaleslead",
    hasNoOpenSalesOrAflevering: "Heeft geen lopend salestraject/aflevering",
    hasNoOpenSales: "Heeft geen lopend salestraject",
    hasNoOpenAflevering: "Heeft geen lopende aflevering",
    hasTelefoon: "Heeft telefoonnummer",
    hasNoTelefoon: "Heeft geen telefoonnummer",
    hasEmail: "Heeft e-mailadres",
    hasNoEmail: "Heeft geen e-mailadres",
    kanBenaderen: "Heeft e-mailadres/telefoonnummer",
    kanNietBenaderen: "Heeft geen e-mailadres/telefoonnummer",
    abort: "Workflow afbreken",
    noAbort: "Workflow laten doorlopen",
    tileAbortDescription: "Kan workflow afbreken",
    expiratie: "Wachttermijn tot 'nee'",
    preconditionDuringWorkflow: "Als voorwaarde niet meer voldoet tijdens workflow",
    expiratieNegate: "Wachttermijn tot 'ja'",
    selectlinkcklick: "Kies link(s)",
    stepEinde: "Einde",
    stepEindeResult: "Resultaat",
    stepEindeResultOk: "Succesvol",
    stepEindeResultNok: "Niet succesvol",
    stepEindeResultNA: "Niet van toepassing",
    stepTimer: "Voor",
    stepTimerTitle: "Wacht",
    selectBericht: "Kies een bericht",
    selectSite: "Kies een website",
    changeBericht: "Kies een ander bericht",
    noBericht: "Geen bericht geselecteerd",
    selectResultaat: "Kies een resultaat",
    stepsRemoved: "verwijderd",
    saved: "Workflow is opgeslagen",
    enterAmount: "Voer een bedrag in",
    selectKlantCategorie: "Kies klantcategorie",
    orderVoertuigCategorie: "Kies voertuigcategorie",
    orderVoertuigBrandstof: "Kies voertuigbrandstof",
    aftersalesVoertuigBrandstof: "Kies voertuigbrandstof",
    keepWhichPath: "Workflow continueren met ",
    optin: "Opt\u2011in: ",
    checkOptin: "Opt\u2011in controleren",
    noOptin: "Geen opt\u2011in controle",
    recenteLeadDelta: "In de afgelopen",
    withinRecentTimespan: "in de afgelopen {timespan}",
    numberOfVisits: "Minimum aantal paginaweergaven sinds startmoment",
    hasRecentSalesLead: "Heeft recent aangemaakte saleslead",
    hasRecentAftersalesLead: "Heeft recent aangemaakte aftersaleslead",
    hasNoRecentLead: "Heeft geen recent aangemaakte lead",
    hasNoRecentSalesLead: "Heeft geen recente saleslead",
    hasNoRecentAftersalesLead: "Heeft geen recente aftersaleslead",
    hasNoRecentSales: "Heeft geen recent aangemaakt salestraject",
    workflowCategories: {
      Id1: "Lead",
      Id2: "Sales",
      Id3: "Aflevering",
      Id4: "Aftersales",
      Id5: "Campagne"
    },
    startTriggers: {
      Id1: "Nieuwe lead",
      Id2: "Nieuwe sales lead",
      Id3: "Nieuwe aftersales lead",
      Id4: "Gemiste lead",
      Id5: "Gemiste sales lead",
      Id6: "Gemiste aftersales lead",
      Id7: "Succesvolle lead",
      Id8: "Succesvolle sales lead",
      Id9: "Succesvolle aftersales lead",
      Id21: "Nieuw salestraject",
      Id22: "Gemist salestraject",
      Id23: "Verkocht",
      Id43: "Goedgekeurd",
      Id45: "Auto besteld",
      Id47: "Kenteken bekend",
      Id49: "Auto binnen",
      Id51: "Afspraak levering",
      Id53: "Auto afgeleverd",
      Id55: "Klant betaald",
      Id56: "Datum uit fabriek bekend",
      Id57: "Productiedatum bekend",
      Id60: "APK",
      Id61: "Volgend onderhoud",
      Id70: "Werkplaatsafspraak",
      Id71: "Werkplaatsfactuur",
      Id80: "Afloop garantie",
      Id65: "Laatste tenaamstelling",
      Id66: "Eerste registratie NL",
      Id67: "Eerste toelating",
      Id90: "Campagne",
    },
    voorwaardeGroepen: {
      Id1: "Lead",
      Id2: "Sales",
      Id3: "Order",
      Id4: "Aflevering",
      Id5: "MAX",
      Id6: "Actiegroep",
      Id7: "Relatie",
      Id8: "Aftersales",
      Id9: "Werkplaatsafspraak",
      Id10: "Werkplaatsbezoek"
    },
    voorwaarden: {
      Id1: "Lead succesvol",
      Id2: "Afsluitreden gemist",
      Id3: "Soort lead",
      Id4: "Soort voertuig (gewenst)",
      Id5: "Merk",
      Id6: "Lead vestiging",
      Id7: "Afsluitreden succesvol",
      Id8: "Lead binnen opvolgtijden",
      Id10: "Leadbron",
      Id11: "Kwalificatie",
      Id12: "Naam gekoppelde relatie",
      Id13: "Soort brandstof",
      Id170: "Relatie is leasemaatschappij",
      Id175: "Relatie is zakelijk",
      Id180: "Relatie is natuurlijk",
      Id20: "Order",
      Id21: "Afsluitreden gemist",
      Id22: "Offerte gemaakt",
      Id23: "Merk",
      Id24: "Soort auto",
      Id25: "Vestiging",
      Id26: "Naam gekoppelde relatie(s)",
      Id171: "Bevat een leasemaatschappij",
      Id176: "Bevat een zakelijke relatie",
      Id181: "Bevat een natuurlijke relatie",
      Id40: "Merk",
      Id41: "Soort auto",
      Id42: "Vestiging",
      Id43: "Klantcategorie",
      Id44: "Voertuigcategorie",
      Id45: "Soort brandstof",
      Id61: "E-mail geopend",
      Id62: "Link geklikt",
      Id63: "Recent gestarte workflow",
      Id91: "Verkocht",
      Id92: "Goedgekeurd",
      Id93: "Besteld",
      Id94: "Auto binnen",
      Id95: "Afspraak levering",
      Id96: "Afgeleverd",
      Id97: "Naam gekoppelde relatie(s)",
      Id172: "Bevat een leasemaatschappij",
      Id177: "Bevat een zakelijke relatie",
      Id182: "Bevat een natuurlijke relatie",
      Id100: "Klant betaald",
      Id101: "Datum uit fabriek",
      Id102: "Productiedatum",
      Id89: "Heeft e-mailadres/telefoonnummer",
      Id81: "Heeft telefoonnummer",
      Id82: "Heeft e-mailadres",
      Id87: "Heeft openstaande lead",
      Id90: "Aantal voertuigen",
      Id151: "Heeft lopend salestraject",
      Id152: "Heeft lopende aflevering",
      Id150: "Is een actieve klant",
      Id160: "Heeft recent aangemaakte lead",
      Id161: "Heeft recent aangemaakt salestraject",
      Id162: "Heeft website bezocht",
      Id110: "Heeft werkplaatsafspraak",
      Id111: "Merk",
      Id112: "Voertuigcategorie",
      Id113: "Vestiging",
      Id114: "APK",
      Id115: "Volgend onderhoud",
      Id140: "Aanvullende garantie",
      Id116: "Eerste eigenaar",
      Id117: "Laatste tenaamstelling",
      Id118: "Naam gekoppelde relatie(s)",
      Id119: "Soort brandstof",
      Id173: "Bevat een leasemaatschappij",
      Id178: "Bevat een zakelijke relatie",
      Id183: "Bevat een natuurlijke relatie",
      Id121: "Eerste toelating",
      Id122: "Lopende garanties",
      Id120: "Vervangend vervoer",
      Id123: "Relatie blijft wachten",
      Id131: "Factuur voor onderhoud",
      Id130: "Factuurbedrag werkplaatsbezoek",
      Id132: "Factuursoort"
    },
    leadInitiatiefType: {
      onbekend: "Onbekend",
      klant: "Klant",
      autobedrijf: "Autobedrijf",
      importeur: "Importeur",
    },
    leadInitiatieven: {
      Klant: "Klant",
      Autobedrijf: "Autobedrijf",
      Importeur: "Importeur",
    },
    leadSoortenBrandstoffen: {
      Benzine: "Benzine",
      Diesel: "Diesel",
      LPG: "LPG",
      Elektrisch: "Elektrisch",
      Hybride: "Hybride",
      HybrideDiesel: "Hybride diesel",
      HybrideLPG: "Hybride LPG",
      CNG: "CNG",
      Waterstof: "Waterstof",
      Overige: "Overige"
    },
    orderVoertuigBrandstoffen: {
      Benzine: "Benzine",
      LPG: "LPG",
      Diesel: "Diesel",
      Elektrisch: "Elektrisch",
      HEV: "HEV",
      PHEV: "PHEV",
      Overig: "Overig"
    },
    aftersalesSoortenBrandstoffen: {
      Benzine: "Benzine",
      Diesel: "Diesel",
      Elektrisch: "Elektrisch",
      LPG: "LPG",
      Aardgas: "Aardgas",
      LPGBenzine: "LPG benzine",
      AardgasBenzine: "Aardgas benzine",
      HybrideBenzine: "Hybride benzine",
      HybrideDiesel: "Hybride diesel",
      HybrideLPG: "Hybride LPG",
      Waterstof: "Waterstof",
      HEV: "HEV",
      PHEV: "PHEV",
      Overig: "Overig"
    },
    soortenLead: {
      Accessoires: "Accessoires",
      APK: "APK",
      Banden: "Banden",
      Bellijst: "Bellijst",
      Bieding: "Bieding",
      Brochure: "Brochure",
      CarConfigurator: "Car configurator",
      Contactverzoek: "Contactverzoek",
      Event: "Event",
      Financiering: "Financiering",
      Garantie: "Garantie",
      HoudMijOpDeHoogte: "Houd mij op de hoogte",
      Klacht: "Klacht",
      Klanttevredenheid: "Klanttevredenheid",
      Laadpaal: "Laadpaal",
      Loyalty: "Loyalty",
      Mailing: "Mailing",
      Offerte: "Offerte",
      Onderhoud: "Onderhoud",
      Overig: "Overig",
      PrivateLease: "Private lease",
      Proefrit: "Proefrit",
      Reparatie: "Reparatie",
      Schade: "Schade",
      ShortLease: "Short lease",
      Showroombezoek: "Showroombezoek",
      Taxatie: "Taxatie",
      TelefonischeLead: "Telefonische lead",
      Terugbelverzoek: "Terugbelverzoek",
      Terugroepactie: "Terugroepactie",
      Verhuur: "Verhuur",
      Verzekering: "Verzekering",
      Webshop: "Webshop",
      Werkplaatsafspraak: "Werkplaatsafspraak",
      Werkplaatsofferte: "Werkplaatsofferte",
      ZakelijkLeasen: "Zakelijk leasen",
      Zoekopdracht: "Zoekopdracht"
    },
    leadTypenVoertuig: {
      OpBasisVanLeadType: "Op basis van leadtype",
      GewenstVoertuig: "Gewenst voertuig",
      HuidigVoertuig: "Huidig voertuig"
    },
    mediaTypen: {
      Id1: "E-mail",
      Id2: "Telefoon",
      Id3: "Messaging"
    },
    leadTypen: {
      Sales: "Sales",
      Aftersales: "Aftersales",
      Fleetsales: "Fleetsales",
      Origineel: "Overnemen uit vorig lead"
    },
    openLeadTypes: {
      Id1: "Sales",
      Id2: "Aftersales",
      Id3: "Sales of aftersales",
      Id4: "Leadtype van startmoment"
    },
    soortenAuto: {
      Nieuw: "Nieuw",
      Occasion: "Occasion",
      VoorraadAuto: "Voorraadauto"
    },
    optinProfielen: {
      Id1: "Autoinformatie",
      Id2: "Merk- en modelinformatie",
      Id3: "Bedrijfsinformatie",
      Id4: "Overig / Commercieel",
      Id5: "Enquêtes"
    },
    aftersalesVoertuigCategorieen: {
      Personenauto: "Personenauto",
      Bedrijfswagen: "Bedrijfswagen"
    },
    orderVoertuigCategorieen: {
      Geel: "Personenauto",
      Grijs: "Bedrijfswagen"
    },
    descriptionSiteVisits: "{count} keer voor {item} |{count} keren voor {item}",
    selectInitiatiefType: "Selecteer initiatief",
    subconditions: "Subvoorwaarden",
    meetsSubconditions: "Voldoet aan",
    allSubconditions: "Alle subvoorwaarden",
    anySubconditions: "Eén van de subvoorwaarden",
    addSubcondition: "Toevoegen",
    subconditionCriteria: "Kies criteria",
    tileSubconditionsAllDescription:
      "Voldoet aan alle<br>{count} subvoorwaarden",
    tileSubconditionsAnyDescription:
      "Voldoet aan één van<br>{count} subvoorwaarden",
    tileSubconditionsCountDescription:
      "Bevat {count} subvoorwaarde | Bevat {count} subvoorwaarden",
    selectRelatienaam: "Voer een naam in",
    category: {
      all: "Alle workflows",
      lead: "Lead",
      sales: "Sales",
      aftersales: "Aftersales",
      delivery: "Aflevering",
      campaign: "Campagne"
    },
    dataEntiteit: {
      relatie: "Relatie",
      voertuig: "Voertuig"
    },
    workflowEntiteit: {
      aflevering: "aflevering",
      bericht: "bericht",
      garantie: "garantie",
      lead: "lead",
      relatie: "relatie",
      verkooptraject: "verkooptraject",
      voertuig: "voertuig",
      werkplaatsafspraak: "werkplaatsafspraak",
      werkplaatsfactuur: "werkplaatsfactuur",
    },
    missingSamenvoegvelden: "samenvoegvelden",
    missingVoorwaarden: "voorwaarden",
    startstepTriggerRestart: "Workflow opnieuw starten",
    startstepTriggerOnceOnly: "Workflow niet opnieuw starten",
    startstepTriggerDataEntry: "op het moment van invullen",
    startstepTriggerDataEntryCapital: "Op het moment van invullen",
    startDescriptionCount: "{filtericon} 1 startvoorwaarde |{filtericon} {count} startvoorwaarden",		
    startDescriptionTriggerBefore: "voor",
    startDescriptionTriggerAfter: "na",
    startDescriptionTrigger: "{startdescription} {beforeafter} de ingevulde datum",	
    startstepTriggerOnDate: "op de ingevulde datum", 
    startstepTriggerDate: "De ingevulde datum", 
    startstepCampagneTriggerDate: "Workflow starten per", 
    startstepCampagneDate: "Datum", 
    startstepCampagneTime: "Tijd", 
    startstepCampagneMax: "Maximum aantal per dag", 
    webtrackingDisplay: "minimaal {amount} weergaven sinds start",
    factuurDisplay: "meer dan {amount} {units}",
    moreThan: "meer dan",
    moreThanTitle: "Meer dan",
    lessThanTitle: "Minder dan",
    equalsTitle: "Gelijk aan",
    timespanPassed: "geleden",
    timespanUnitsPassed: "{timespan} geleden",
    timespanIsLess: "Is korter dan",    
    timespanIsOver: "Is langer dan",
    timespanIsLessSmall: "is korter dan",    
    timespanIsOverSmall: "is langer dan",
    dateIsWithIn: "valt binnen",
    dateOptionSelect: "Selecteer een vergelijking",
    dateIsWithInTimespan: "binnen {timespan} {beforeorafter}",
    tenaamstellingDisplayMore: "langer dan {timespan} geleden",
    tenaamstellingDisplayLess: "korter dan {timespan} geleden",
    nextMaintenanceDisplayBefore: "binnen {timespan} voor Volgend onderhoud",
    nextMaintenanceDisplayAfter: "binnen {timespan} na Volgend onderhoud",
    nextAPKDisplayBefore: "binnen {timespan} voor APK",
    nextAPKDisplayAfter: "binnen {timespan} na APK",
    lastSoldVehicleDisplayBefore: "binnen {timespan} voor Laatste tenaamstelling",
    lastSoldVehicleDisplayAfter: "binnen {timespan} na Laatste tenaamstelling",
    voertuigDisplayMore: "meer dan {amount}",
    voertuigDisplayLess: "minder dan {amount}",
    voertuigDisplayEqual: "gelijk aan {amount}",
    enterQuantity: "Voer een aantal in",
    addDoelgroep: "Toevoegen",
    multipleDoelgroepInformation: "Wanneer een relatie zich in meerdere doelgroepen bevindt, wordt voor het vullen van samenvoegvelden in berichten de bovenste doelgroep gebruikt, in dit geval: {doelgroepNaam}",
    errorMessages: {
      andOr: "en/of",
      readonly: "Deze workflow kan niet meer worden aangepast omdat de campagne al is gestart.",
      workflowTitle: "Naam workflow ontbreekt.",
      startStepNoTrigger: "Startmoment in start is verplicht.",
      startStepNoGarantie: "Een garantiesoort is verplicht.",
      startStepNoStartMoment: "Een startmoment is verplicht.",
      startStepNoStartTijd: "Een starttijd is verplicht.",
      startStepNoAantalPerDag:
        "Een maximaal aantal workflows per dag is verplicht.",
      startStepNoDoelgroepen:
        "Er moet ten minste één doelgroep geselecteerd worden.",
      startStepNoEntiteit:
        "Er moet een keuze worden gemaakt om te starten per relatie of voertuig.",
      startStepInactiveDoelgroep: "Één of meerdere doelgroepen zijn inactief.",
      startStepNoVoertuigEntiteit: "Er moet in alle doelgroepen een voertuig zitten.",
      eindStep: "Een of meerdere eindstappen ontbreken.",
      eindStepNoResult: "Resultaten in eindstappen zijn verplicht.",
      wachtStep: "Periode in wachtstappen zijn verplicht.",
      berichtStep: "Bij een berichtenstap moet een bericht geselecteerd zijn.",
      berichtStepMissingEntities:
        "Het bericht bevat {entiteiten} die niet gevuld worden in deze workflow.",
      voorwaardeStepMissingEntities:
      "De workflow bevat {entiteiten} die niet ge-evalueerd kunnen worden.",
      lefStep: {
        omschrijving: "Omschrijvingen in LEF stappen zijn verplicht.",
        kwalificaties: "Kwalificaties in LEF stappen zijn verplicht.",
        leadtype:
          "Lead type in LEF stappen is verplicht bij sales en afleveringen.",
      },
      voorwaardeCombinatieStep: {
        criteria:
          "Criteria in voorwaardestappen met meerdere subvoorwaarden is verplicht.",
      },
      voorwaardeStep: {
        criteria: "Criteria in voorwaardestappen zijn verplicht.",
        gemist: "Gemiste afsluitredenen in voorwaardestappen zijn verplicht.",
        soortLead: "Soort lead in voorwaardestappen zijn verplicht.",
        gewensteAutosoorten:
          "Gewenste autosoorten in voorwaardestappen zijn verplicht.",
        carmake: "Merken in voorwaardestappen zijn verplicht.",
        vestigingen: "Vestigingen in voorwaardestappen zijn verplicht.",
        afsluitRedenen:
          "Afsluitsuccesvol redenen in voorwaardestappen zijn verplicht.",
        link: "Links in voorwaardestappen zijn verplicht.",
        leadvoertuigtype:
        "Het soort voertuig in lead voorwaardestappen is verplicht.",
        gemistSales:
          "Gemiste afsluitredenen in voorwaardestappen zijn verplicht.",
        salesAutosoorten:
          "Sales autosoorten in voorwaardestappen zijn verplicht.",
        aftersalesVoertuigCategorieen:
          "Voertuig categorie in voorwaardestappen zijn verplicht.",
        salesAutomake: "Merken in voorwaardestappen zijn verplicht.",
        orderAutomake: "Merken in voorwaardestappen zijn verplicht.",
        vestigingenExternSales:
          "Vestigingen in voorwaardestappen zijn verplicht.",
        orderAutosoorten:
          "Order autosoorten in voorwaardestappen zijn verplicht.",
        vestigingenExternOrder:
          "Vestigingen in voorwaardestappen zijn verplicht.",
        actiegroepVerkocht:
          "Actiegroep verkocht in voorwaardestappen zijn verplicht.",
        actiegroepGoedgekeurd:
          "Actiegroep goedgekeurd in voorwaardestappen zijn verplicht.",
        actiegroepBesteld:
          "Actiegroep besteld in voorwaardestappen zijn verplicht.",
        actiegroepAutoBinnen:
          "Actiegroep auto binnen in voorwaardestappen zijn verplicht.",
        actiegroepAfspraakLevering:
          "Actiegroep afspraak levering in voorwaardestappen zijn verplicht.",
        actiegroepAfgeleverd:
          "Actiegroep afgeleverd in voorwaardestappen zijn verplicht.",
        klantCategorie: "Klantcategorie in voorwaardestappen is verplicht.",
        kwalificatie: "Kwalificatie in voorwaardestappen is verplicht.",
        leadbron: "Leadbron in voorwaardestappen is verplicht.",
        lopendeWorkflows:
          "Lopende workflows in voorwaardestappen zijn verplicht.",
        timespan: "Periode in voorwaardestappen is verplicht.",
        referenceid: "Referentiedatum in voorwaardestappen zijn verplicht.",
        garantie: "Garanties in voorwaardestappen zijn verplicht.",
        factuursoorten: "Factuursoorten in voorwaardestappen zijn verplicht.",
        heeftOpenLead: "Leadtype in voorwaardestappen is verplicht.",
        heeftInitiatief: "Initiatief in voorwaardestappen is verplicht.",
        optinProfiel:
          "Profiel bij opt-in controle in voorwaardestappen is verplicht",
        optinMedium:
          "Medium bij opt-in controle in voorwaardestappen is verplicht",
        expiratieMaxSubvoorwaarden:
          "Wachttermijn kan niet worden gebruikt bij meerdere subvoorwaarden",
        bedragnegatief: "Geen geldig bedrag ingevuld.",
        aantalnegatief: "Geen geldig aantal ingevuld.",
        invalidCompare: "Geen geldig vergelijker ingevuld.",
        relatieNamen:
          "Een of meerdere namen in voorwaardestappen zijn verplicht.",
        orderVoertuigCategorie:
          "Een of meerdere categorieen in voorwaardestappen zijn verplicht.",
        orderVoertuigBrandstof:
          "Een of meerdere brandstoffen in voorwaardestappen zijn verplicht.",
        leadVoertuigBrandstof:
          "Een of meerdere brandstoffen in voorwaardestappen zijn verplicht.",
        aftersalesVoertuigBrandstof:
          "Een of meerdere brandstoffen in voorwaardestappen zijn verplicht.",
        invalidwebtrackers:
          "Een of meerdere webtrackers in voorwaardestappen zijn verplicht.",
      },
      campagneAantalLimitExceeded: "Het maximum aantal dat per dag verstuurd mag worden is gelimiteerd op {count}. Indien dit opgehoogd dient te worden, verzoeken wij u contact op te nemen met onze Customer Service.",
      campagneStartToday: "Let op: Deze campagne zal over {count} minuten starten.",
      berichtStepMissingVestiging: 
        "Omdat er geen aflevering, salestraject, lead of voertuig gegarandeerd kan worden op dit punt in de workflow, zal de standaardvestiging gebruikt worden voor het invullen van de vestiging samenvoegvelden in dit bericht."
    },
  },

  sjablonen: {
    title: "Berichten",
    searchPlaceholder: "Zoek bericht...",
    blocks: "Blokken",
    general: "Algemeen",
    inworkflows: "Workflows",
    communication: "Communicatie",
    category: "Categorie",
    workflow: "Workflow",
    make: "Merk",
    labels: "Labels",
    email: "E-mail",
    sms: "SMS",
    copy: "Kopiëren",
    createTemplate: "Maak template",
    createdTemplate: "Template aangemaakt",
  },

  sjabloon: {
    copied: "Kopie van {title}",
    panelTitle: "Berichtdetails",
    panelDetailTitle: "Algemene informatie",
    title: "Bericht",
    subject: "Onderwerp",
    category: "Categorie",
    label: "Label",
    insertTitle: "Voer een titel in...",
    insertSubject: "Voer een onderwerp in...",
    chooseCategory: "Kies een categorie",
    allCategories: "Alle categorieën",
    actief: "Actief",
    inactief: "Inactief",
    allLabels: "All labels",
    selectLabel: "Selecteer label(s)",
    email: "E-mail",
    sms: "SMS",
    amount: 'Aantal',
    normalCharacters: 'normale tekens',
    specialCharacters: 'speciale tekens',
    maximumCharacters: 'maximum bereikt!',
    opgeslagen: "Opgeslagen",
    mergetags: "Samenvoegvelden",
    selectmergetag: "Samenvoegveld invoegen",
    undoSMS: "SMS bericht is verwijderd",
    undoEmail: "E-Mail bericht is verwijderd",
    chooseBericht: "Kies een bericht",
    modelSpecificLayout: "Model specifieke opmaak",
    merk: "Merk",
    model: "Model",
    allMakes: "Alle merken",
    selectMerk: "Merk toevoegen",
    selectModel: "Model toevoegen",
    nonSelectedMerk: "Alle merken",
    nonSelectedModel: "Alle modellen",
    selectedMerk: "Overige merken",
    selectedModel: "Overige modellen",
    notAvailableMerk: "Merk niet beschikbaar",
    notAvailableOpties: "Geen opties beschikbaar",
    noResult: "Geen resultaat gevonden",
    merkDeleted: "Merk is verwijderd",
    modelDeleted: "Model is verwijderd",
    preview: "Voorbeeld",
    sendtest: "Test bericht",
    saved: "Bericht is opgeslagen",
    saveRow: "Rij opslaan",
    savedRow: "Opgeslagen rij",
    warnExistingRowUpdate: "Deze rij is al opgeslagen als",
    voorkeurinformatie:
      "Door de tabbladen te verslepen kan de voorkeur tijdens het versturen worden bepaald",
    smsinformatie:
      "Het aantal smsjes wordt vertoond. Een sms met normale leestekens bevat de standaard 160 karakter. Met speciale leestekens is het maximaal aantal karakters 70. De lengte van de sms is afhankelijk van de inhoud van de samenvoegvelden.",
    noWorkflow: "Niet gekoppeld aan een workflow",
    testSMSDetailTitle: "Test SMS",
    testSMSTitle: "Test verzenden aan",
    insertPhoneNumber: "Voer een mobielnummer in...",
    testMailDetailTitle: "Test e-mail",
    testEmailTitle: "Test verzenden aan",
    insertEmail: "Voer een e-mailadres in...",
    testMailSend: "Test e-mail is verzonden",
    testMailSendFailed: "Test e-mail is niet verzonden",
    testSMSSend: "Test SMS is verzonden",
    testSMSSendFailed: "Test SMS is niet verzonden",
    webtrackingactive: "Webtracking",
    consumersettingsactive: "Opt\u2011in",
    consumersettingslabel: "Profiel",
    consumersettings: "Kies een profiel...",
    errormessages: {
      title: "Het veld bericht is verplicht",
      omschrijving: "Het veld onderwerp is verplicht",
      testemailaddress: "Een geldig e-mailadres is verplicht",
      testephonenumber: "Een geldig mobielnummer is verplicht",
      isActiveInvalid: "Bericht wordt nog gebruikt in een actieve workflow",
      consumersettingsInvalid: "Kies een profiel bij Opt\u2011in",
      consumersettingsTag:
        "Een of meerdere e-mails missen een opt\u2011in afmeldlink",
    },
  },

  sentMessages: {
    filterAllMedia: "Alle communicatie soorten",
    filterAllUnsent: "Alle meldingen",
    filterAllWorkflows: "Alle workflows",
    filterAllMessages: "Alle berichten",
    selectWorkflow: "Selecteer een workflow",
    selectMessage: "Selecteer een bericht",
    versturen: "Versturen",
    menuTitle: "Niet verzonden berichten",
    sent: "Verzonden berichten",
    title:
      "Niet verzonden bericht ({count})| Niet verzonden berichten ({count})",
    workflow: "Workflow",
    workflows: "Workflows",
    email: "E-mail",
    sms: "SMS",
    recipient: "Ontvanger",
    status: "Foutstatus",
    errorMessage: "Foutmelding",
    delete: "Verwijderen",
    confirmDelete: "Bevestigen",
    communication: "Communicatie",
    berichtTitel: "Bericht titel",
    badgeTitle: "1 niet verzonden bericht | {count} niet verzonden berichten",
    notificationType: "Soort melding",
    messages: "Berichten",
  },
};
